import moment from "moment";

export default {
    methods: {
        lastUpdateDescription(lastActivityInstance) {
            const date = moment(lastActivityInstance.created_at).format('MM/DD/YYYY, HH:mm A');

            return `Last Updated by ${lastActivityInstance.user_name} ${date}`;
        },
        modifiedAtDescription(user, strIsoDate, descriptionStart = 'Modified by') {
            const dateDesc = moment(strIsoDate).format('MM/DD/YYYY [at] HH:mm A');

            return `${descriptionStart} ${user.full_name} on ${dateDesc}.`;
        }
    }
}
