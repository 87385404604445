<template>
    <div class="template-shortcodes">
        <div class="template-shortcodes__header">
            <CButton class="template-shortcodes__close" size="sm" color="link" @click="$emit('close')">
                <CIcon name="cil-arrow-left"></CIcon>
                Back
            </CButton>
            <span class="template-shortcodes__heading">Merge fields</span>
        </div>

        <div class="template-shortcodes__body">
            <CNSelect v-model="filter.type" class="template-shortcodes__item" label="Type" :disabled="loading"
                      :options="shortcodesTypes"/>

            <div class="fields-search">
                <CIcon class="fields-search__icon" icon="cil-search"></CIcon>
                <input :disabled="loading" v-model="filter.search" class="fields-search__input" type="text"
                       placeholder="All Merge Fields"/>
            </div>
            <ul class="template-shortcodes__item template-shortcodes__list">
                <li v-if="loading" class="template-shortcodes__list-item template-shortcodes__list-item--spinner">
                    <CSpinner color="secondary"/>
                </li>

                <template v-else-if="shortcodesList.length">
                    <li v-for="shortcode in shortcodesList" :key="shortcode.id" class="template-shortcodes__list-item">
                        <button class="template-shortcodes__list-button" type="button"
                                @click="handleShortcodeSelect(shortcode)">
                            {{ shortcode.name }}
                        </button>
                    </li>
                </template>

                <li v-else class="template-shortcodes__list-item template-shortcodes__list-item--spinner">
                    {{ $t('No fields found') }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateEditorShortcodes',
    emits: ['close'],
    inject: ['toast'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            availableShortcodes: [],
            validationAvailableShortcodes: [],
            shortcodesTypes: [],
            filter: {
                type: 'All',
                search: '',
            }
        }
    },
    computed: {
        shortcodesList() {
            let result = this.availableShortcodes;

            return this.availableShortcodes.filter(shortcode => {
                return (this.filter.type === 'All' || shortcode.type === this.filter.type)
                    && (this.filter.search.length === 0 || shortcode.name.toLowerCase().includes(this.filter.search.toLowerCase()));
            });
        }
    },
    mounted() {
        this.fetchAvailableShortcodes()
    },
    methods: {
        async fetchAvailableShortcodes() {
            this.toggleLoading();

            await this.$http.shortcodes
                .getCompanyShortcodes()
                .then(response => this.defineShortcodesFromResponse(response.data.data))
                .finally(() => this.toggleLoading());
        },
        defineShortcodesFromResponse(responseData) {
            this.availableShortcodes = responseData;

            this.shortcodesTypes = [
                'All',
                ...new Set(this.availableShortcodes.map((s) => s.type)),
            ];
        },
        handleShortcodeSelect(shortcode) {
            navigator.clipboard.writeText(shortcode.placeholder);
            this.toast('success', `${shortcode.name} copied to clipboard!`);
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
}
</script>

<style lang="scss" scoped>
.template-shortcodes {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__buttons {
        margin-top: auto;
        border-top: 1px solid #C3CDD5;
        display: flex;
        background-color: #fff;
        z-index: 10;
        gap: 15px;
        padding-top: 25px;
    }

    &__header {
        position: sticky;
        top: -20px;
        z-index: 2;
        background: #fff;
        display: flex;
        flex-direction: column;
    }

    &__heading {
        font-size: 20px;
        font-weight: 600;
        color: #1C262F;
        border-top: 1px solid #C3CDD5;
    }

    &__close {
        height: 38px;
        display: flex;
        align-items: center;
        gap: 9px;
        padding: 0;
        font-weight: 600;
        color: #1C262F;
        font-size: 16px;
        text-decoration: none;
        width: 60px;
    }

    &__body {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__validation {
        position: relative;
    }

    &__validation-popover {
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        z-index: 2;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        border-radius: 0 0 8px 8px;
        border-left: 1px solid #9FAFBC;
        border-right: 1px solid #9FAFBC;
        border-bottom: 1px solid #9FAFBC;
        overflow: hidden;
        flex-grow: 1;
        overflow-y: auto;
        height: 300px;
        max-height: 300px;
    }

    &__list-button {
        width: 100%;
        padding: 8px 16px;
        background-color: transparent;
        border: none;
        text-align: left;

        &:hover {
            background-color: rgba(238, 238, 239, 0.7);
        }
    }

    &__list-item {
        &--spinner {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__button {
        width: 100%;
    }
}

.fields-search {
    border: 1px solid #9fafbc;
    border-radius: 4px 4px 0 0;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    &__search {
        height: 15px;
        color: #BAC6CF;
    }

    &__input {
        flex: auto;
        border: none;
        outline: none;
        font-size: 14px;

        &::placeholder {
            color: #BAC6CF;
        }
    }
}
</style>
