<template>
    <div ref="wrapper" class="template-settings-sidebar" :aria-disabled="disabled">
        <div class="template-settings-sidebar__body">
            <div>
                <input v-model="templateData.name" class="template-settings-sidebar__name w-100 form-text"
                       :class="{ 'template-settings-sidebar__name_error': !!validationErrors.name }"
                       type="text" maxlength="245" placeholder="Template name"
                       @blur="validateField(this.templateData, this.validationRules, 'name')"/>

                <small v-if="validationErrors.name" class="text-error">
                    {{ validationErrors.name }}
                </small>
            </div>

            <OptionsDropdown label="Task action" class="template-settings-sidebar__item" :aria-disabled="true"
                             :options="taskActionOptions" v-model="templateType"/>

            <OptionsDropdown label="Status" v-model="selectedStatus" :options="taskStatuses"/>

            <DueDateSelector v-if="templateData.recurrence" :settings="templateData.recurrence"
                             :permissions="['recurrence']" @saved="templateData.recurrence = templateData"/>

            <CNTextarea v-model="templateData.description" label="Description" :max-length="1000"/>

            <ul class="users-edit">
                <li>{{ modifiedAtDescription(template.modified_by_user, template.updated_at) }}</li>
                <li>{{ modifiedAtDescription(template.creator_user, template.created_at, 'Created by') }}</li>
            </ul>
        </div>

        <div class="template-settings-sidebar__footer">
            <CButton color="primary" variant="outline" @click="cancel">Cancel</CButton>
            <CButton color="primary" @click="submit">Save</CButton>
        </div>

        <div class="overflow-modal">
            <CModal :visible="showDraftModal" @close="toggleShowDraftModal">
                <div class="draft-modal">
                    <CIcon class="draft-modal__icon" name="cil-file"></CIcon>
                    <div class="draft-modal__body">
                        <h3 class="draft-modal__title">Publish your template</h3>
                        <p class="draft-modal__text">
                            Are you sure you want to keep this template as a draft? You need to change the status to
                            “Published” if you are ready to make the template available in the system.
                        </p>
                    </div>
                    <div class="draft-modal__buttons">
                        <CButton color="primary" variant="outline" @click="toggleShowDraftModal">Cancel</CButton>
                        <CButton color="primary" @click="toggleShowDraftModal(); save()">Save draft</CButton>
                    </div>
                </div>
            </CModal>
        </div>

        <ConfirmDialog ref="confirmDialog"/>
    </div>
</template>

<script>
import CNTextarea from '@/components/ui/CNTextarea/CNTextarea';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import {isEqual} from 'lodash/lang';
import rules from '@/utils/validator/rules';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import recordActivity from "@/mixin/recordActivity";
import formValidator from "@/mixin/formValidator";
import unsavedChanges from "@/mixin/unsavedChanges";
import {TEMPLATE_TYPES} from "@/domain/Entities/Template/templateTypes";
import {PUBLISHED_TEMPLATE_STATUS, TEMPLATE_STATUSES} from "@/domain/Entities/Template/templateStatuses";
import OptionsDropdown from "@/components/CreateTask/OptionsDropdown.vue";

export default {
    name: 'TemplateSettingsSidebar',
    mixins: [formValidator, recordActivity, unsavedChanges],
    components: {OptionsDropdown, DueDateSelector, ConfirmDialog, CNTextarea},
    emits: ['cancel', 'save'],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Object,
            default: () => ({}),
        },
        documentHasChanged: {
            type: Boolean,
            default: false,
        },
    },
    data: (props) => ({
        tags: [],
        showDraftModal: false,
        taskStatuses: TEMPLATE_STATUSES,
        taskActionOptions: TEMPLATE_TYPES,
        selectedStatus: props.template.is_published ? 'Published' : 'Draft',
        templateType: props.template.task_settings.type,
        templateData: {
            name: props.template.name,
            recurrence: props.template.task_settings_recurrence,
            description: props.template.description,
            is_published: props.template.is_published,
        },
        validationRules: {
            name: [rules.required, rules.exceptCharacters('<>:"/\\|?*')],
        },
        nameError: null,
    }),
    computed: {
        hasTemplateDataChanged() {
            return !isEqual(this.templateData, this.origin) || this.documentHasChanged;
        },
    },
    watch: {
        selectedStatus(value) {
            this.templateData.is_published = value === PUBLISHED_TEMPLATE_STATUS;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.origin = this.$deepClone(this.templateData);
        });
    },
    methods: {
        submit() {
            this.validateAndSubmit(this.templateData, this.validationRules, () => {
                if (!this.templateData.is_published) {
                    return this.toggleShowDraftModal();
                }

                this.save();
            });
        },
        save() {
            this.$emit('save', this.templateData);
        },
        async cancel() {
            if (await this.triggerUnsavedChangesCheck(this.hasTemplateDataChanged)) {
                this.$emit('cancel');
            }
        },
        toggleShowDraftModal() {
            this.showDraftModal = !this.showDraftModal;
        }
    },
};
</script>

<style lang="scss" scoped>
.draft-modal {
    padding: 40px;
    width: 688px;

    &__icon {
        height: 80px !important;
        width: 80px !important;
        color: #bac6cf;
        margin: 0 auto;
        display: block;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
    }

    &__text {
        color: #677a89;
        font-size: 18px;
    }

    &__body {
        text-align: center;
        margin: 40px 0;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 15px;

        & button {
            min-width: 200px;
        }
    }
}

.users-edit {
    padding: 10px 0 10px 18px;
    border-top: 1px solid silver;

    & li {
        font-size: 12px;
        color: #677a89;
    }
}

.template-settings-sidebar {
    position: relative;
    overflow-y: auto;
    padding: 0 20px;

    &__name {
        color: #1c262f;
        border: 1px solid #9FAFBC;
        border-radius: 8px;
        padding: 4px 16px;
        font-weight: 600;
        font-size: 20px;
        outline: none;

        &_error {
            border-bottom: 1px solid red;
        }
    }

    &__header {
        position: sticky;
        top: -20px;
        z-index: 10;
        margin: -20px;
        padding: 20px;
        background: #fff;
    }

    &__heading {
        font-size: 24px;
        font-weight: 600;
    }

    &__body {
        margin-top: 40px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__info {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__info-item {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__label {
        flex: 0 0 50%;
    }

    &__value {
        flex: 1 1 50%;
    }

    &__footer {
        position: sticky;
        bottom: -10px;
        margin: -20px;
        padding: 20px;
        background-color: #fff;
        z-index: 10;
    }

    button {
        min-width: 100px;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}
</style>
