<template>
    <CModal content-class-name="loading-modal" size="md" :visible="visible">
        <CModalBody>
            <div class="header">
                <span class="title">{{ title }}</span>
            </div>
            <div class="body">
                <CSpinner class="spinner mr-3" color="primary" size="sm"></CSpinner>
                <span>{{ description }}</span>
            </div>
        </CModalBody>
    </CModal>
</template>

<script>
export default {
    name: 'FormModalLoader',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Loading...',
        },
        description: {
            type: String,
            default: 'Please, wait until the system finishes processing.'
        }
    },
}
</script>

<style scoped lang="scss">
.loading-modal {
    overflow: hidden;
    box-shadow: none !important;

    & .modal-body {
        padding: 0 !important;
        border-radius: 0.3em;
        overflow: hidden;
    }

    .header {
        background: #202e39;
        padding: 16px 32px;

        .title {
            font-size: 16px;
            font-weight: 400;
            color: white;
        }
    }

    .body {
        padding: 16px 32px;
        min-width: 400px;
        max-width: 500px;

        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .spinner {
            flex-shrink: 0;
        }
    }
}
</style>
